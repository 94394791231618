import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { ButtonV2, Card, Typography } from '@vartanainc/design-system';
import { useMemo, useState } from 'react';

import TextInputField from '../../designSystem/TextInput/TextInputField';
import AutoLoad from '../AutoLoad';
import {
  appVariants,
  commonRegex,
  FIELD_INVALID_MESSAGES,
  FIELD_REQUIRED_MSG,
} from '../../constants/common.constants';
import { showToast, isRenderedFromHubspot } from '../../utils/helpers';
import useResellers from '../../utils/hooks/resellers';
import { INVITE_RESELLER } from '../../graphql/mutations';
import { ReactComponent as SendIcon } from '../../assets/arrow-blue.svg';
import FormTextArea from '../FormTextArea';
import CancelConfirmationModal from '../Modals/CancelConfirmation';
import FreeTextDropdownInput from '../../designSystem/FreeTextDropdownInput/FreeTextDropdownInput';

const initialValues = {
  reseller: {
    number: '',
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
  },
  message: '',
};

const CAN_GO_BACK = 'canGoBack';

const validateSchema = Yup.object().shape({
  reseller: Yup.object().shape({
    number: Yup.string(),
    companyName: Yup.string().required(FIELD_REQUIRED_MSG),
    firstName: Yup.string()
      .required(FIELD_REQUIRED_MSG)
      .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.firstName),
    lastName: Yup.string()
      .required(FIELD_REQUIRED_MSG)
      .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.lastName),
    email: Yup.string()
      .email('Please enter a valid email address')
      .required(FIELD_REQUIRED_MSG),
  }),
  message: Yup.string(),
});

export function InviteResellerCard({ app, customerNumber }) {
  const navigate = useNavigate();
  const [resellers, resellersLoading] = useResellers();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const renderedFromHubspot = isRenderedFromHubspot();
  const { search } = useLocation();

  const useQueryParams = useMemo(() => new URLSearchParams(search), [search]);
  const showCancelBtn = useMemo(() => {
    return useQueryParams.get(CAN_GO_BACK) !== 'false'; // we should hide the cancel btn if canGoBack is false
  }, [useQueryParams]);

  const [inviteReseller] = useMutation(INVITE_RESELLER);

  const emailNotDeliverableErrorMessage = 'is not deliverable';

  const handleSubmit = async (values) => {
    const resellerName = values.reseller.companyName;
    const reseller = resellers.find((opt) => opt.value === resellerName);

    const vals = values;
    if (reseller) vals.reseller.number = reseller.number;

    // getting errors along with data
    const { data, errors } = await inviteReseller({
      variables: { ...vals, customerNumber },
    });

    // showing error toast only for email not deliverable error
    if (errors?.email?.length > 0) {
      const error = errors.email[0];
      if (error === emailNotDeliverableErrorMessage) {
        showToast('error', `Email ${emailNotDeliverableErrorMessage}`);
      }
    }

    // showing success message and navigating
    if (get(data, 'inviteReseller.number')) {
      const message = `Invitation is sent to ${resellerName}`;
      showToast('success', message);

      if (app === appVariants.vendor) navigate(-1);
    }
  };

  function onCancel() {
    const shouldNavigateBack = app === appVariants.vendor || renderedFromHubspot;
    if (shouldNavigateBack) navigate(-1);
    else window.close();
  }

  return (
    <div className="gap-x-4 h-full w-full">
      <CancelConfirmationModal
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        onConfirm={() => onCancel()}
      />
      <div className="w-7/12 small-card-width-range mx-auto">
        <Card
          tabindex="0"
          variant="fullWidth"
          title={(
            <div className="gap-2.5">
              <div className="flex gap-1 items-center">
                <SendIcon />
                <Typography variant="heading20" color="color-black-100">
                  Invite partner to collaborate
                </Typography>
              </div>
              <Typography variant="paragraph14" color="color-black-100">
                Enable partners to view deal details, upload quotes and process future deals.
              </Typography>
            </div>
          )}
          parentContainerClassName="max-w-[85.375rem] min-w-[68.75rem]"
          content={(
            <Formik
              initialValues={initialValues}
              validationSchema={validateSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => {
                const submitEnabled = formik.dirty && formik.isValid;

                return (
                  <AutoLoad loading={resellersLoading} containerClassName="my-10">
                    <Form className="space-y-4 mt-4 pb-4">
                      <FreeTextDropdownInput
                        label="Partner name"
                        name="reseller.companyName"
                        options={resellers}
                      />
                      <div className="grid grid-cols-2 gap-4">
                        <TextInputField name="reseller.firstName" label="First name" />
                        <TextInputField name="reseller.lastName" label="Last name" />
                      </div>
                      <TextInputField label="Email" name="reseller.email" type="email" />

                      <FormTextArea
                        name="message"
                        label="Message for partner (optional)"
                        placeholder="Include a note for the recipient..."
                        className="flex-1"
                      />

                      <div className="flex justify-between items-center pt-4">
                        {showCancelBtn ? (
                          <ButtonV2
                            variant={{ type: 'ghost', typography: 'paragraph14' }}
                            type="button"
                            text="Cancel"
                            iconLeft="chevron_left"
                            onClick={() => {
                              if (formik.dirty) {
                                setOpenConfirmationModal(true);
                              } else onCancel();
                            }}
                            btnText="Cancel"
                          />
                        ) : (
                          <div /> // placeholder div to maintain the layout if cancel button is hidden
                        )}
                        <AutoLoad loading={formik.isSubmitting}>
                          <ButtonV2
                            type="submit"
                            disabled={!submitEnabled}
                            text="Submit"
                            variant={{ type: 'primary', typography: 'paragraph14' }}
                          />
                        </AutoLoad>
                      </div>
                    </Form>
                  </AutoLoad>
                );
              }}
            </Formik>
          )}
        />
      </div>
    </div>
  );
}

InviteResellerCard.propTypes = {
  app: PropTypes.oneOf(Object.values(appVariants)),
  customerNumber: PropTypes.string.isRequired,
};

InviteResellerCard.defaultProps = {
  app: appVariants.vendor,
};
